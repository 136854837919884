// import React, {Suspense, lazy} from "react";
// import {Redirect, Switch, Route} from "react-router-dom";
// import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
// import {BuilderPage} from "./pages/BuilderPage";
// import {MyPage} from "./pages/MyPage";
// import {DashboardPage} from "./pages/DashboardPage";
// import ManajementUser from "./modules/Users/ManajementUser";
// import InputUserBaru from "./modules/Users/InputUserBaru";
// import TesKomponen from "./modules/Tes/TesKomponen";

import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import DashboarKantah from "./pages/DashboardKantah";
import ManajementUser from "./modules/Users/ManajementUser";
import InputUserBaru from "./modules/Users/InputUserBaru";
import DataKantah from "./modules/Kantah/DataKantah";
import ManajemenWMS from "./modules/Kantah/ManajemenWMS/ManajemenWMS";
import { connect, useSelector } from "react-redux";
import { StateToProps } from "../services/redux_/StateToProps";
import { DispatchToProps } from "../services/redux_/DispatchToProps";
import { injectIntl } from "react-intl";
import _Api from "../services/_Api";
import UploadWFS from "./modules/Kantah/ManajemenWMS/UploadWMS";
import HistoryLokasi from "../app/pages/history-lokasi/HistoryLokasi";
import { LokasiKantah } from "./modules/Kantah/LokasiKantah/LokasiKantah";
import { UploadShp } from "./pages/petugas-ukur/UploadShp";
import { MapSHP } from "./pages/upload-shp/mapShp";

// import PetugasUkur from './pages/petugas-ukur/PetugasUkur'

const PetugasUkur = lazy(() => import("./pages/petugas-ukur/PetugasUkur"));

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

// export default function BasePage() {
//     // useEffect(() => {
//     //   console.log('Base page');
//     // }, []) // [] - is required if you need only one call
//     // https://reactjs.org/docs/hooks-reference.html#useeffect

//     return (
//         <Suspense fallback={<LayoutSplashScreen/>}>
//             <Switch>
//                 {
//                     /* Redirect from root URL to /dashboard. */
//                     <Redirect exact from="/" to="/dashboard"/>
//                 }
//                 <ContentRoute path="/dashboard" component={DashboardPage}/>
//                 <ContentRoute path="/builder" component={BuilderPage}/>
//                 <ContentRoute path="/manajemenuser" component={ManajementUser}/>
//                 <ContentRoute path="/createOrUpdate/:id" component={InputUserBaru}/>
//                 {/* <ContentRoute path="/tes" component={TesKomponen}/> */}

//                 <ContentRoute path="/my-page" component={MyPage}/>
//                 <Route path="/google-material" component={GoogleMaterialPage}/>
//                 <Route path="/petugas-ukur" component={PetugasUkur}/>
//                 <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
//                 <Route path="/e-commerce" component={ECommercePage}/>
//                 <Redirect to="error/error-v1"/>
//             </Switch>
//         </Suspense>
//     );

function BasePage(props) {
  const history = useHistory();

  const { user } = useSelector((state) => state.auth);
  // const cekAuth = () =>{
  //   _Api.get("users?id="+user.id).then(res=>{
  //     console.log("QuiclUserToggler ",props)
  //     props.setJenis(res.data[0].role)
  //     props.setUserLogin(res.data[0])
  //   })

  //   // if(props.userLogin === "")
  //   // history.push("/dashboard")
  // }

  useEffect(() => {
    // cekAuth()
  }, []);

  const RouteAdmin = () => {
    return (
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/manajemenuser" component={ManajementUser} />
        <ContentRoute path="/createOrUpdate/:id" component={InputUserBaru} />
        <ContentRoute path="/DataKantah" component={DataKantah} />
        {/* <ContentRoute path="/my-page" component={MyPage} /> */}
        {/* <Route path="/petugas-ukur" component={PetugasUkur} /> */}
        {/* <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} /> */}
        {/* <ContentRoute path="/ManajemenWMS" component={ManajemenWMS} /> */}
        <Redirect to="/" />
      </Switch>
    );
  };

  const RouteKantah = () => {
    return (
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/dashboard" component={DashboarKantah} />
        <ContentRoute path="/DataKantah" component={DataKantah} />
        <ContentRoute path="/petugas-ukur" component={PetugasUkur} />
        <ContentRoute path="/history-lokasi" component={HistoryLokasi} />
        <ContentRoute path="/ManajemenWMS" component={ManajemenWMS} />
        <ContentRoute path="/LokasiKantah" component={LokasiKantah} />
        <ContentRoute path="/upload-shp" component={UploadShp} />
        <ContentRoute path="/map-shp" component={MapSHP} />
        <Redirect to="/" />
      </Switch>
    );
  };

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {user.role == "admin" ? RouteAdmin() : RouteKantah()}
    </Suspense>
  );
}

export default injectIntl(connect(StateToProps, DispatchToProps)(BasePage));
