import React, { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  WMSTileLayer,
  useMap,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { ShpWMS } from "./shpWms";
import { GetFeatureInfo } from "./featureWMS";

export const MapSHP = () => {
  return (
    <div style={{ padding: 5, backgroundColor: "white", borderRadius: 10 }}>
      <MapContainer
        style={{ width: "100%", height: 500, zIndex: 3 }}
        center={[-2.131647, 106.1168]}
        zoom={14}
        maxZoom={18}
        scrollWheelZoom={true}
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Roadmap">
            <ReactLeafletGoogleLayer
              apiKey="AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg"
              type={"roadmap"}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite">
            <ReactLeafletGoogleLayer
              apiKey="AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg"
              type={"satellite"}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Terrain">
            <ReactLeafletGoogleLayer
              apiKey="AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg"
              type={"terrain"}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Hybrid">
            <ReactLeafletGoogleLayer
              apiKey="AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg"
              type={"hybrid"}
            />
          </LayersControl.BaseLayer>

          <LayersControl.Overlay checked name="WMS">
            <WMSTileLayer
              attribution="Weather data © 2012 IEM Nexrad"
              url="https://portal.smartptsl.com/geoserver/portal/wms"
              version="1.1.0"
              opacity={1}
              transparent
              layers="portal:wms_shp_data"
              srs="EPSG:4326"
              format="image/png"
              // bounds={bounds}
            />
          </LayersControl.Overlay>
          {/* <ShpWMS /> */}
        </LayersControl>
        <GetFeatureInfo
          wmsUrl="https://portal.smartptsl.com/geoserver/portal/wms"
          layers="portal:wms_shp_data"
        />
      </MapContainer>
    </div>
  );
};
