import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter, TablePagination } from "@material-ui/core";
import TableCell from "@mui/material/TableCell";
import { Button } from "react-bootstrap";
import _Api from "../../../services/_Api";
import Swal from "sweetalert2";

export const ListsSHP = ({ rows, fetchSHP }) => {
  const [loading, setLoading] = React.useState(false);
  // PAGINATE TABLE ===
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {}, [rows]);

  const syncSHP = (id) => {
    setLoading(true);
    Swal.fire({
      title: "Sync...",
      text: "Silahkan tunggu sampai proses sinkornasi selesai.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    _Api
      .post(`/pu/shp/sync/${id}`)
      .then(({ data }) => {
        Swal.close();
        Swal.fire({
          title: "Berhasil!",
          text: "File SHP berhasil Sinkron.",
          icon: "success",
        });
        setLoading(false);
        fetchSHP();
      })
      .catch((error) => {
        Swal.close();
        Swal.fire({
          title: "Gagal!",
          text: "File SHP gagal Sinkorn.",
          icon: "error",
        });
        setLoading(false);
      });
  };

  const extractFileName = (filePath) => {
    // Split the string by '/' and get the last element (file name)
    const parts = filePath.split("/");
    return parts[parts.length - 1];
  };

  const renderData = (rowsPerPage > 0
    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : rows
  ).map((row, index) => {
    let nama = extractFileName(`${row.file}`);
    const date = new Date(row.created_at);

    const day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns month from 0-11
    const year = date.getFullYear();

    const hours = date
      .getHours()
      .toString()
      .padStart(2, "0");
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

    return (
      <TableRow key={index}>
        <TableCell style={{ paddingTop: 5, paddingBottom: 5, fontSize: 12 }}>
          {index + 1}
        </TableCell>
        <TableCell style={{ paddingTop: 5, paddingBottom: 5, fontSize: 12 }}>
          {nama}
        </TableCell>
        <TableCell style={{ paddingTop: 5, paddingBottom: 5, fontSize: 12 }}>
          {formattedDate}
        </TableCell>
        <TableCell style={{ paddingTop: 5, paddingBottom: 5, fontSize: 12 }}>
          {row.sync ? (
            <span className="badge badge-success">Sukses</span>
          ) : (
            <button
              onClick={() => syncSHP(row.id)}
              className="btn btn-primary btn-sm"
              title="Sync."
            >
              <i className="fa fa-cloud"></i>&nbsp; Sync.
            </button>
          )}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Table>
      <TableHead>
        <TableRow hover style={{ padding: 10 }}>
          <TableCell style={{ fontWeight: "bold", fontSize: 12 }}>#</TableCell>
          <TableCell style={{ fontWeight: "bold", fontSize: 12 }}>
            Nama File
          </TableCell>
          <TableCell style={{ fontWeight: "bold", fontSize: 12 }}>
            Created At
          </TableCell>
          <TableCell style={{ fontWeight: "bold", fontSize: 12 }}>
            Sync
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{renderData}</TableBody>

      <TableFooter>
        <TableRow>
          <TablePagination
            colSpan={3}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};
