/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

let auth = JSON.parse(localStorage.getItem("persist:auth"));
let currentUser = auth?.user ? JSON.parse(auth?.user) : {};

export function AsideMenuListKantah({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        <li className="menu-section ">
          <h4 className="menu-text">Main Menu</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* <li
              className={`menu-item ${getMenuItemActive("/builder")}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manajemenuser">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">Management User</span>
            </NavLink>
          </li> */}

        <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        {/* <li
              className={`menu-item ${getMenuItemActive("/builder")}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/DataKantah">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">Data Kantah</span>
            </NavLink>
          </li> */}

        <li
          className={`menu-item ${getMenuItemActive("/petugas-ukur")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/petugas-ukur">
            <span className="svg-icon menu-icon">
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/> */}
              <i className="fa fa-users"></i>
            </span>
            <span className="menu-text">Surveyor</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/petugas-ukur/semua-peta-area"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/petugas-ukur/semua-peta-area">
            <span className="svg-icon menu-icon">
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/> */}
              <i className="fa fa-object-group"></i>
            </span>
            <span className="menu-text">Hasil Pengukuran</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/history-lokasi")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/history-lokasi">
            <span className="svg-icon menu-icon">
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/> */}
              <i className="fa fa-map-marker"></i>
            </span>
            <span className="menu-text">History Lokasi</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/builder")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/ManajemenWMS">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Manajemen WMS</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/builder")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/LokasiKantah">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Lokasi Surveyor</span>
          </NavLink>
        </li>
        {currentUser.kantah.kode === "1601" ? (
          <li
            className={`menu-item ${getMenuItemActive("/builder")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/upload-shp">
              <span className="svg-icon menu-icon">
                <i className="fa fa-cloud"></i>
              </span>
              <span className="menu-text">Upload SHP</span>
            </NavLink>
          </li>
        ) : (
          ""
        )}

        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
