import {
  MapContainer,
  TileLayer,
  LayersControl,
  useMapEvent,
  Popup,
} from "react-leaflet";
import React, { useState } from "react";
import { WMSTileLayer } from "react-leaflet";
import L from "leaflet";

export const GetFeatureInfo = ({ wmsUrl, layers }) => {
  const [featureInfo, setFeatureInfo] = useState(null);
  const [popupPos, setPopupPos] = useState(null);

  // Use map event to handle click
  useMapEvent("click", async (e) => {
    const map = e.target;
    const { lat, lng } = e.latlng;
    const bbox = map.getBounds().toBBoxString();
    const size = map.getSize();

    // Build the GetFeatureInfo URL
    const url = `${wmsUrl}?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&QUERY_LAYERS=${layers}&LAYERS=${layers}&INFO_FORMAT=application/json&FEATURE_COUNT=1&SRS=EPSG:4326&BBOX=${bbox}&WIDTH=${
      size.x
    }&HEIGHT=${size.y}&X=${Math.floor(e.containerPoint.x)}&Y=${Math.floor(
      e.containerPoint.y
    )}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.features && data.features.length > 0) {
        setFeatureInfo(data.features[0].properties); // Adjust depending on your data structure
        setPopupPos(e.latlng);
      } else {
        setFeatureInfo(null);
      }
    } catch (error) {
      console.error("Error fetching feature info", error);
    }
  });

  return (
    featureInfo &&
    popupPos && (
      <Popup position={popupPos}>
        <div>
          <h4>Feature Info</h4>
          {Object.entries(featureInfo).map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong> {value}
            </div>
          ))}
        </div>
      </Popup>
    )
  );
};
