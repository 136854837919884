import React, { useEffect, useState } from "react";
import _Api from "../../../services/_Api";
import { ButtonUplod } from "../../../services/_Button";
import Swal from "sweetalert2";
import { ListsSHP } from "../upload-shp/listsShp";
import { NavLink } from "react-router-dom";

let auth = JSON.parse(localStorage.getItem("persist:auth"));
let currentUser = auth?.user ? JSON.parse(auth?.user) : {};

export const UploadShp = () => {
  const [progressInfos, setProgressInfos] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = React.useState([]);

  const upload = () => {
    setLoading(true);
    Swal.fire({
      title: "Uploading...",
      text: "Silahkan tunggu sampai proses upload selesai.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let formData = new FormData();
    formData.append("file", selectedFiles[0]);
    formData.append("kantah_id", currentUser.kantah.kode);
    _Api
      .post("/pu/shp/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        Swal.close();
        Swal.fire({
          title: "Berhasil!",
          text: "File SHP berhasil diupload.",
          icon: "success",
        });
        setLoading(false);
        setShowForm(false);
        fetchSHP();
      })
      .catch((error) => {
        let err = error;
        let message = "File SHP gagal diupload.";

        if (error.response) {
          message = error.response.data.message || error.response.data;
        } else if (error.request) {
          message = error.request;
        } else {
          message = err?.message;
        }
        Swal.close();
        Swal.fire({
          title: "Gagal!",
          text: message,
          icon: "error",
        });
        setLoading(false);
        setShowForm(false);
        fetchSHP();
      });
  };

  const selectFiles = (event) => {
    const selected = event.target.files;
    setSelectedFiles(selected);
  };

  const fetchSHP = async () => {
    setLoading(true);
    await _Api
      .get("/pu/shp/list")
      .then((res) => {
        setRows(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSHP();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-custom gutter-b example example-compact">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Upload SHP</h3>
            </div>
            <div className="card-toolbar">
              <button
                onClick={() => setShowForm(!showForm)}
                className="btn btn-primary"
              >
                <i className="fa fa-plus-circle"></i> Tambah
              </button>
              &nbsp;
              <NavLink className="menu-link" to="/map-shp">
                <button className="btn btn-warning">
                  <i className="fa fa-map"></i> Open Map
                </button>
              </NavLink>
            </div>
          </div>
          <div className="card-body">
            {showForm ? (
              <>
                <div className="form-group mb-4">
                  <label className="form-label required">File SHP</label>
                  <input
                    className="form-control"
                    type="file"
                    accept=".zip"
                    id="my_file"
                    multiple
                    onChange={selectFiles}
                    // ref={this.fileUpload}
                  />
                </div>
                <ButtonUplod onClick={() => upload()} title="Upload" />
                <hr />
              </>
            ) : (
              ""
            )}
            {loading ? (
              <>Loading...</>
            ) : (
              <ListsSHP rows={rows} fetchSHP={fetchSHP} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
