import React, { useMemo } from "react";
import { Rectangle, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet.gridlayer.googlemutant";

export const ShpWMS = () => {
  const map = useMap();
  var myWMS = L.tileLayer.wms(
    "https://portal.smartptsl.com/geoserver/portal/wms?",
    {
      layers: "portal:wms_shp_data",
      format: "image/png",
      transparent: true,
      version: "1.1.0",
      zIndex: 600,
      opacity: 1,
      attribution: "WMS SmartPortal",
    }
  );

  map.addLayer(myWMS);
  const innerHandlers = useMemo(() => ({}), [map]);
  return <></>;
};
